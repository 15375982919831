import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './MazeComponent.css';

class Cell {
  constructor(x, y, cellSize, ctx) {
    this.x = x;
    this.y = y;
    this.cellSize = cellSize;
    this.ctx = ctx;
    this.visited = false;
    this.walls = { top: true, right: true, bottom: true, left: true };
  }

  show() {
    let x = this.x * this.cellSize;
    let y = this.y * this.cellSize;

    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 2;

    if (x === 0) {
      x = 1;
    }
    if (y === 0) {
      y = 1;
    }

    if (x.toFixed(0) === (this.ctx.canvas.width - this.cellSize).toFixed(0)) {
      x = this.ctx.canvas.width - this.cellSize - 1;
    }
    if (y.toFixed(0) === (this.ctx.canvas.height - this.cellSize).toFixed(0)) {
      y = this.ctx.canvas.height - this.cellSize - 1;
    }

    if (this.walls.top) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + this.cellSize, y);
      this.ctx.stroke();
    }
    if (this.walls.right) {
      this.ctx.beginPath();
      this.ctx.moveTo(x + this.cellSize, y);
      this.ctx.lineTo(x + this.cellSize, y + this.cellSize);
      this.ctx.stroke();
    }
    if (this.walls.bottom) {
      this.ctx.beginPath();
      this.ctx.moveTo(x + this.cellSize, y + this.cellSize);
      this.ctx.lineTo(x, y + this.cellSize);
      this.ctx.stroke();
    }
    if (this.walls.left) {
      this.ctx.beginPath();
      this.ctx.moveTo(x, y + this.cellSize);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
    }

    if (this.visited) {
      this.ctx.fillStyle = "white";
      this.ctx.fillRect(x, y, this.cellSize, this.cellSize);
    }
  }

  checkNeighbors(grid, cols, rows) {
    let neighbors = [];

    let top = grid[this.index(this.x, this.y - 1, cols, rows)];
    let right = grid[this.index(this.x + 1, this.y, cols, rows)];
    let bottom = grid[this.index(this.x, this.y + 1, cols, rows)];
    let left = grid[this.index(this.x - 1, this.y, cols, rows)];

    if (top && !top.visited) neighbors.push(top);
    if (right && !right.visited) neighbors.push(right);
    if (bottom && !bottom.visited) neighbors.push(bottom);
    if (left && !left.visited) neighbors.push(left);

    if (neighbors.length > 0) {
      let r = Math.floor(Math.random() * neighbors.length);
      return neighbors[r];
    } else {
      return undefined;
    }
  }

  index(x, y, cols, rows) {
    if (x < 0 || y < 0 || x > cols - 1 || y > rows - 1) {
      return -1;
    }
    return x + y * cols;
  }
}

class Maze {
  constructor(canvasId, rows, cols) {
    this.canvas = document.getElementById(canvasId);
    this.ctx = this.canvas.getContext("2d");
    this.rows = rows;
    this.cols = cols;
    this.cellSize = this.canvas.width / cols;
    this.grid = [];
    this.stack = [];
    this.current = null;
    this.entry = null;
    this.exit = null;

    this.setupGrid();
    this.current = this.grid[0];
    this.current.visited = true;

    this.entry = this.grid[0];
    this.exit = this.grid[this.grid.length - 1];

    this.entry.walls.left = false;
    this.exit.walls.right = false;

    this.draw();
  }

  setupGrid() {
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.cols; x++) {
        let cell = new Cell(x, y, this.cellSize, this.ctx);
        this.grid.push(cell);
      }
    }
  }

  draw() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.grid.forEach(cell => cell.show());

    let next = this.current.checkNeighbors(this.grid, this.cols, this.rows);
    if (next) {
      next.visited = true;

      this.stack.push(this.current);
      this.removeWalls(this.current, next);
      this.current = next;
    } else if (this.stack.length > 0) {
      this.current = this.stack.pop();
    } else {
      this.current = null;
      console.log('Maze generation complete!');
    }

    this.drawArrow(this.entry.x * this.cellSize + this.cellSize / 2, this.entry.y * this.cellSize + this.cellSize / 2, 'green', 'right');
    this.drawArrow(this.exit.x * this.cellSize + this.cellSize, this.exit.y * this.cellSize + this.cellSize / 2, 'red', 'right');

    if (this.current) {
      requestAnimationFrame(() => this.draw());
    }
  }

  removeWalls(a, b) {
    let dx = a.x - b.x;
    let dy = a.y - b.y;

    if (dx === 1) {
      a.walls.left = false;
      b.walls.right = false;
    } else if (dx === -1) {
      a.walls.right = false;
      b.walls.left = false;
    }

    if (dy === 1) {
      a.walls.top = false;
      b.walls.bottom = false;
    } else if (dy === -1) {
      a.walls.bottom = false;
      b.walls.top = false;
    }
  }

  drawArrow(x, y, color, direction) {
    this.ctx.fillStyle = color;
    this.ctx.beginPath();
    if (direction === 'left') {
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + 6, y - 6);
      this.ctx.lineTo(x + 6, y + 6);
    } else if (direction === 'right') {
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x - 6, y - 6);
      this.ctx.lineTo(x - 6, y + 6);
    }
    this.ctx.closePath();
    this.ctx.fill();
  }
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const difficultyMapping = {
  1: { rows: 14, cols: 14 },
  2: { rows: 16, cols: 16 },
  3: { rows: 18, cols: 18 },
  4: { rows: 20, cols: 20 },
  5: { rows: 25, cols: 25 },
  6: { rows: 30, cols: 30 },
  7: { rows: 35, cols: 35 },
  8: { rows: 40, cols: 40 },
  9: { rows: 45, cols: 45 },
  10: { rows: 50, cols: 50 },
};


const MazeComponent = () => {
  const query = useQuery();
  const difficulty = query.get("levels") || "1";

  useEffect(() => {
    const diffArr = difficulty.split(",").map(Number);

    diffArr.forEach((diff, i) => {
      const canvas = document.createElement('canvas');
      canvas.id = `maze_${diff}_${i}`;
      canvas.width = 350;
      canvas.height = 350;

      document.querySelector('.container').appendChild(canvas);

      const { rows, cols } = difficultyMapping[diff] || difficultyMapping[2];

      new Maze(`maze_${diff}_${i}`, rows, cols);
    });
  }, []);

  return (
    <div className="container"></div>
  );
};

export default MazeComponent;
